import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const HeaderWrapper = styled.header`
  background: black;
  height: 125px;
`

const HeaderContent = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;
  display: flex;
  justify-items: space-between;
  align-items: center;
`

const BrandItem = styled.h1`
  margin: 0;
  flex: 1;
`

const NavContent = styled.ul`
  display: flex;
  flex: 1;
`

const NavItem = styled.li`
  list-style-type: none;
  padding: 1rem;
  flex: 1;
`

const LinkWrapper = styled(Link)`
  color: white;
  text-decoration: none;
`

const Header = ({ menuLinks, siteTitle }) => (
  <HeaderWrapper>
    <HeaderContent>
      <BrandItem>
        <LinkWrapper to="/">{siteTitle}</LinkWrapper>
      </BrandItem>
      <div>
        <nav>
          <NavContent>
            {menuLinks.map(link => (
              <NavItem>
                <LinkWrapper to={link.link}>{link.name}</LinkWrapper>
              </NavItem>
            ))}
          </NavContent>
        </nav>
      </div>
    </HeaderContent>
  </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
